<template>
    <v-dialog
            persistent
            v-model="splitDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="700px"
            min-width="600px"
            max-width="800px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <template v-if="type === 'NSV'">
                    {{ $t('message.splitBundle') + ": " + bundleNo }}
                </template>
                <template v-if="type === 'SV'">
                    {{ $t('message.splitPallet') + ": " + bundleNo }}
                </template>
            </v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="splitBundleForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs4 lg2 md4 py-1 class="align-center">{{ }}</v-flex>
                        <v-flex xs4 lg5 md4 py-1 class="align-center font-weight-bold black--text text--darken-1">
                            <template v-if="type === 'NSV'">
                                {{ $t('message.originalBundle') }}
                            </template>
                            <template v-if="type === 'SV'">
                                {{ $t('message.originalPallet') }}
                            </template>
                        </v-flex>
                        <v-flex xs4 lg5 md4 py-1 class="align-center font-weight-bold black--text text--darken-1 pl-2">
                            <template v-if="type === 'NSV'">
                                {{ $t('message.newBundle') }}
                            </template>
                            <template v-if="type === 'SV'">
                                {{ $t('message.newPallet') }}
                            </template>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg2 md4 py-1 class="align-center">
                            <template v-if="type === 'NSV'">
                                {{ $t('message.bundleNo') }}
                            </template>
                            <template v-if="type === 'SV'">
                                {{ $t('message.palletNo') }}
                            </template>
                        </v-flex>
                        <v-flex xs4 lg5 md4 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="revisedBundle.bundleno"
                                    autocomplete="off"
                                    class="custom pl-1 bg-lightorange"
                                    dense
                                    hide-details="auto"
                                    @change="revisedBundle.bundleno = $event"
                            />
                            <span class="mt-1">
                                <template v-if="type === 'NSV'">
                                    {{ $t('message.typeNewBundleNo') }}
                                </template>
                                <template v-if="type === 'SV'">
                                    {{ $t('message.typeNewPalletNo') }}
                                </template>
                            </span>
                        </v-flex>
                        <v-flex xs4 lg5 md4 py-1 class="pl-2">
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="newBundle.bundleno"
                                    autocomplete="off"
                                    class="custom pl-1 bg-lightblue"
                                    dense
                                    hide-details="auto"
                                    @change="newBundle.bundleno = $event"
                            />
                            <span class="mt-1">
                                <template v-if="type === 'NSV'">
                                    {{ $t('message.typeNewBundleNo') }}
                                </template>
                                <template v-if="type === 'SV'">
                                    {{ $t('message.typeNewPalletNo') }}
                                </template>
                            </span>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg2 md4 py-1 class="align-center">{{ $t('message.pieces') }}</v-flex>
                        <v-flex xs4 lg5 md4 py-1>
                            <v-text-field
                                :rules="[...validationRules.number, ...validationRules.minimumZero]"
                                :value="revisedBundle.piece"
                                autocomplete="off"
                                class="custom pl-1 bg-lightorange"
                                dense
                                hide-details="auto"
                                readonly
                            />
                        </v-flex>
                        <v-flex xs4 lg5 md4 py-1 class="pl-2">
                            <v-text-field
                                :rules="[...validationRules.required, ...validationRules.number, ...validationRules.minimumZero]"
                                :value="newBundle.piece"
                                autocomplete="off"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                @change="updateSplitBundleValue('newBundle.piece',$event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg2 md4 py-1 class="align-center">{{ $t('message.volAndQty') }}</v-flex>
                        <v-flex xs4 lg5 md4 py-1>
                            <v-text-field
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="revisedBundle.volume"
                                autocomplete="off"
                                class="custom pl-1 bg-lightorange"
                                dense
                                hide-details="auto"
                                readonly
                            />
                        </v-flex>
                        <v-flex xs4 lg5 md4 py-1 class="pl-2">
                            <v-text-field
                                :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                :value="newBundle.volume"
                                autocomplete="off"
                                class="custom pl-1 bg-lightblue"
                                dense
                                hide-details="auto"
                                @change="updateSplitBundleValue('newBundle.volume',$event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row mt-5>
                        <v-flex xs4 lg2 md4 py-1 class="align-center">{{ $t('message.splitReason') }}</v-flex>
                        <v-flex xs8 lg10 md4 py-1>
                            <v-lazy>
                                <v-textarea
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="newBundle.split_remark"
                                    autocomplete="off"
                                    auto-grow
                                    clearable
                                    dense
                                    hide-details="auto"
                                    rows="2"
                                    solo
                                    @change="newBundle.split_remark = $event"
                                />
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="splitDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.split"
                        color="info"
                        small
                        @click="addSplitBundle()"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="alertDialogClosed"
        />
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {isObject} from "Helpers/helpers";
    import {Decimal} from 'decimal.js'

    const SimpleAlert = () => import("Components/Appic/SimpleAlert");

    export default {
        name: "SplitBundle",
        components: {SimpleAlert},
        props: ['bundleId','bundleNo','dialog','type'],
        data(){
            return {
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                loading: {
                    split: false
                },
                split_dialog: false,
                validForm: true
            }
        },
        computed: {
            ...mapFields('stock',{
                originalBundle: 'split.originalBundle',
                revisedBundle: 'split.revisedBundle',
                newBundle: 'split.newBundle'
            }),
            ...mapGetters([
                'validationRules'
            ]),
            splitDialog: {
                get() {
                    return this.split_dialog;
                },
                set(value){
                    this.split_dialog = value
                    if(value === false) {
                        this.$emit('dialog-closed')
                    }
                }
            },
        },
        methods: {
            ...mapActions('stock',{
                getBundleToSplitById: 'getBundleToSplitById',
                resetBundleToSplit: 'resetBundleToSplit',
                splitBundle: 'splitBundle'
            }),
            addSplitBundle () {
                if(this.revisedBundle.bundleno === this.newBundle.bundleno){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.newBundleNoUnchanged')
                    this.loading.split = false
                } else if(this.revisedBundle.bundleno === this.originalBundle.bundleno){
                   this.dialogs.error = true
                   this.dialogs.error_message = this.$t('message.revisedBundleNoUnchanged')
                   this.loading.split = false
                } else {
                    let valid = this.$refs.splitBundleForm.validate()
                    if( valid ){
                        this.loading.split = true
                        let payload = {
                            stockId: this.bundleId,
                            newBundle: this.newBundle,
                            revisedBundle: this.revisedBundle
                        }
                        this.splitBundle(payload)
                            .then(response => {
                                if (response.status === 'success') {
                                    this.$toast.success(this.$t('message.successes.bundleSplit'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    this.loading.split = false
                                    this.$emit('split-done')
                                } else {
                                    if(isObject(response.message)){
                                        //extract validation errors
                                        const errors = Object.values(response.message)
                                        errors.forEach(errorObj => {
                                            if (Array.isArray(errorObj)) {
                                                errorObj.forEach(error => {
                                                    this.$toast.error(error,
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'error_outline'
                                                        }
                                                    )
                                                })
                                            } else {
                                                this.$toast.error(this.$t('message.errors.bundleNotSplit'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            }
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.bundleNotSplit'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                    this.resetBundleToSplit()
                                    this.loading.split = false
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.bundleNotSplit'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.split = false
                            })
                    } else {
                        this.$toast.error(this.$t('message.errors.bundleNotSplit'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                }
            },
            alertDialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            updateSplitBundleValue ( field, value) {
                switch( field ){
                    case'newBundle.volume':
                        const originalVolume = new Decimal(this.originalBundle.volume + '')
                        const newVolume = new Decimal(value + '');
                        this.revisedBundle.volume = originalVolume.greaterThanOrEqualTo(newVolume) ? originalVolume.minus(newVolume) : 0
                        this.newBundle.volume = newVolume
                        break
                    case'newBundle.piece':
                        const originalPiece = parseInt(this.originalBundle.piece)
                        const newPiece = parseInt(value);
                        this.revisedBundle.piece = originalPiece > 0 && originalPiece >= newPiece ? originalPiece - newPiece : 0
                        this.newBundle.piece = newPiece
                        break
                    default:
                }
            },
        },
        watch: {
            dialog(value) {
                if(value === true){
                    this.getBundleToSplitById( this.bundleId )
                } else {
                    this.resetBundleToSplit()
                }
                this.split_dialog = value
            }
        },
    }
</script>

<style>
.bg-lightblue {
    background-color: lightblue;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.bg-lightorange {
    background-color: #FFAB91;
}
.v-text-field__slot input {
    padding-left: 10px;
    text-align: left;
}
.custom.v-text-field {
    font-size: 20px !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none !important;
}
.custom.v-select .v-icon {
    font-size: 20px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width) .v-input__append-inner .v-input__icon>.v-icon, .v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width) .v-input__prepend-inner .v-input__icon>.v-icon {
    margin-top: 0px !important;
    font-size: 20px;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-left: auto !important;
    padding-left: 0px !important;
}
</style>